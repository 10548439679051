<template>
  <div style="padding: 20px">
    <header>
      <p style="font-size: 24px">全景图片</p>
      <!-- <Icon
        type="ios-arrow-dropleft-circle"
        size="36"
        class="ico"
        title="退回"
        @click="back"
      /> -->
      <!-- <Button @click="add"
              type="info">添加全景</Button> -->
    </header>

    <!-- 列表 -->
    <div v-if="tabledata.length>0">
      <div v-for="item in tabledata"
           :key="item.id"
           class="map-box">
        <span class="type-name"
              @click="toDetail(item)">全景</span>

        <div class="map"
             @click="toDetail(item)">
          <img :src="item.panoramaImgPath"
               alt="">
        </div>
        <div class="info">
          <p class="title"
             @click="toDetail(item)"
             :title="item.name">{{ item.name }}</p>
          <!-- <span>{{ item.create_time }}</span> -->
          <!-- 操作 -->
          <div class="more"
               @click="showMore(item.id)">
            <Icon type="md-more"
                  size="22" />
            <div :style="{display: moreAct == item.id ?'block':'none'}">
              <p @click.stop="edit(item)">
                <Icon type="md-create"
                      style="margin-right: 0.15rem" />编辑
              </p>
              <p @click.stop="del(item.id)">
                <Icon type="ios-trash"
                      style="margin-right: 0.15rem" />删除
              </p>
            </div>
          </div>

        </div>
      </div>
      <div class="page"
           v-if="total > 5">
        <Page show-total
              :page-size="limit"
              :total="total"
              :current="parseInt(page)"
              size="small"
              show-sizer
              :page-size-opts='[10,15,20]'
              @on-page-size-change="pageSizeChange"
              @on-change="pageChange" />
      </div>
    </div>
    <div v-else
         class="noMedia">
      <img :src="noData"
           alt="">
      暂无全景文件
    </div>

    <!-- 全景弹窗 -->
    <!-- 上传弹窗 -->
    <!-- <Modal class="upload-modal"
           :loading="loading"
           v-model="uploadModal"
           title="新增"
           @on-ok="ok"
           @on-cancel="cancel">
      <Form ref="formValidate"
            :model="uploadData"
            :rules="ruleValidate"
            :label-width="80">
        <FormItem label="名称"
                  prop="mapName">
          <Input v-model.trim="uploadData.mapName"
                 maxlength="15"
                 show-word-limit
                 placeholder="请输入名称"></Input>
        </FormItem>
        <FormItem label="图片">
          <Upload :max-size="10240"
                  action
                  :format="['jpg', 'png', 'jpeg']"
                  :on-exceeded-size="handleMaxSize"
                  :on-format-error="handleFormatError"
                  :before-upload="uploadBefore">
            <Button icon="ios-cloud-upload-outline"
                    type="info">上传</Button>
          </Upload>
          <div>{{ fileName }}
            <Icon type="md-trash"
                  v-if="fileName"
                  size="26"
                  :color="$store.state.darkTheme?'#fff':'#000'"
                  @click="clearImage"
                  style="cursor: pointer" />
          </div>

        </FormItem>
        <FormItem label="位置区域">
          <p style="height: 22px;color:#C9CACA">{{ location }}</p>
        </FormItem>
        <div id="addMap"></div>
      </Form>
    </Modal> -->
    <Modal v-model="uploadModal"
           footer-hide
           width=680
           @on-cancel="cancel"
           :mask-closable="false">
      <p slot="header"
         style="text-align: center">
        上传全景
      </p>

      <div>
        <p style="font-size:12px">名称:</p>
        <Input v-model.trim="uploadData.mapName"
               placeholder="必填"
               maxlength="15"
               show-word-limit></Input>

        <p style="font-size:12px;margin:25px 0 5px">位置区域： {{ location }}</p>
        <div id='map'>
          <div class="mapSearch"
               v-if="$online">
            <Icon type="ios-search"
                  style="margin:0 12px"
                  size="18"
                  color="#777" />
            <input type="text"
                   id="mapSearch_2"
                   autocomplete="off"
                   placeholder="搜索地点" />
          </div>

          <div class='tiptop'
               v-if="!uploadData.lat">单击地图选择拍摄地点</div>
          <div id="addMap"></div>
        </div>
        <Upload :max-size="20480"
                ref="OrUpLoad"
                action
                accept="image/jpg, image/jpeg, image/png,"
                :format="['jpg', 'png', 'jpeg']"
                :on-exceeded-size="handleMaxSize"
                :on-format-error="handleFormatError"
                :before-upload="uploadBefore"
                :on-error="addMediaError">
          <Button type="primary"
                  style="display: inline-block; width: 648px;margin:20px auto 0">上传</Button>
        </Upload>

      </div>
    </Modal>
    <!-- 删除弹窗提示 -->
    <Modal v-model="delModel"
           @on-ok="delsure()"
           :loading="delLoading"
           title="确认删除?"
           :wdith="200">
      <p>
        <Icon type="md-alert"
              style="color: #ff9900"
              size="20" />是否删除该全景图？
      </p>
    </Modal>
  </div>
</template>

<script>
import {
  PanoramawebAdd,
  PanoramawebList,
  PanoramawebDel,
} from '@/utils/javaApi.js';
import Api from '@/utils/api.js';
import noData from '@/assets/img/noData.png';
export default {
  name: 'vrMgr',

  data() {
    return {
      noData,
      loading: true,
      delLoading: true,
      rowId: null,
      fileName: '',
      uploadUrl: '',
      categoryList: [],
      loadingStatus: false,
      uploadModal: false,
      limit: 15,
      page: 1,
      total: 8,
      uploadData: {
        mapName: '',
        image: '',
        lat: '',
        lng: '',
      },
      location: '',
      addMap: {}, // 新增窗口的地图
      uploadFormData: new FormData(),
      ruleValidate: {
        mapName: [
          {
            required: true,
            message: '请填写全景图名称',
            trigger: 'blur',
            min: 1,
            max: 30,
          },
        ],
      },
      SelectID: 0,
      delModel: false,
      showDetail: false,
      tabledata: [],
      uploadDisabled: false,
      moreAct: '-1',
      model: [],
      model1: '',
    };
  },

  methods: {
    showMore(e) {
      if (this.moreAct == e) {
        this.moreAct = '-1';
      } else {
        this.moreAct = e;
      }
    },
    // back() {
    //   this.$router.go(-1);
    // },
    del(id) {
      console.log(id);
      this.rowId = id;
      this.delModel = true;
    },
    uploadFile() {
      //   this.loadingStatus = true;
      //   setTimeout(() => {
      //     this.file = null;
      //     this.loadingStatus = false;
      //     this.$Message.success('Success');
      //   }, 1500);
    },
    getAddsByPosition(mylnglat) {
      let that = this;
      this.addMap.plugin('AMap.Geocoder', function () {
        let geocoder = new AMap.Geocoder();
        geocoder.getAddress(mylnglat, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            that.location = result.regeocode.formattedAddress;
          }
        });
      });
    },
    uploadBefore(file) {
      //   console.log(this.uploadData);
      // 开始上传前,选中文件后执行
      if (this.uploadData.mapName == '') {
        this.$Message.warning('请先填写全景名称');
        this.file_size = 0;
        return false;
      } else if (!this.uploadData.lat || !this.uploadData.lng) {
        this.$Message.warning('请先选择位置信息');
        this.file_size = 0;
        return false;
      }

      this.fileName = file.name;
      this.uploadFormData.append('image', file);
      this.loadingStatus = true;

      let eid = sessionStorage.getItem('Eid')
        ? sessionStorage.getItem('Eid')
        : -1;
      this.uploadFormData.append('mapName', this.uploadData.mapName);
      this.uploadFormData.append('lat', this.uploadData.lat);
      this.uploadFormData.append('lng', this.uploadData.lng);
      this.uploadFormData.append('describe', '描述');
      this.uploadFormData.append('pid', 0);
      this.uploadFormData.append('eid', eid);

      this.$post(PanoramawebAdd(), this.uploadFormData)
        .then((res) => {
          if (res.code === 1) {
            this.uploadModal = false;
            this.$Message.success(res.msg_customer);
            this.getPanorame();
          } else {
            this.$Message.error(res.msg_customer);
          }
          this.uploadModal = false;
          this.uploadFormData = new FormData();
        })
        .catch((err) => {
          this.$Message.error(err);
          this.uploadFormData = new FormData();
        });

      return false;
    },
    toDetail(row) {
      //   let panoramaInfo = JSON.stringify(row);
      //   localStorage.setItem('panoramaInfo', panoramaInfo);
      console.log(row);
      this.$router.push({
        // name: 'DetailPanorama',
        name: 'EdtiPanorama',
        query: { id: row.id, watch: 2 },
      });
    },
    // 获取全景图列表
    getPanorame() {
      this.$post(PanoramawebList(), {
        page: this.page,
        limit: this.limit,
        team_id: JSON.parse(sessionStorage.getItem('user_info')).team_id,
      })
        .then((res) => {
          if (res.code === 1) {
            this.total = res.data.total;
            this.tabledata = res.data.data;
            // 初始化获取
          } else {
            this.$Message.error(res.msg_customer);
          }
        })
        .catch((err) => {
          this.$Message.error(err);
        });
    },
    // 翻页
    pageChange(ind) {
      this.page = ind;
      this.getPanorame();
    },
    pageSizeChange(l) {
      this.limit = l;
      this.pageChange(1);
    },
    // 关闭弹窗
    closeImgModal() {
      //   this.viewer.destroy();
      //   this.showDetail = false;
      //   document.documentElement.style.overflowY = 'auto';
      //   this.displayPanoramadata = false;
    },
    /**
     * 上传
     */
    // 打开弹窗
    add() {
      this.editmode = false;
      this.uploadModal = true;
      this.uploadData.mapName = '';
      this.uploadData.lat = '';
      this.uploadData.lng = '';
      this.uploadFormData = new FormData();
      this.fileName = '';
      let that = this;
      let marker = '';
      let layer = [];
      if (this.$online) {
        // layer = [new AMap.TileLayer.Satellite()];
        layer = [new AMap.TileLayer()];
      } else {
        layer = [
          new AMap.TileLayer({
            getTileUrl: function (x, y, z) {
              return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png';
            },
            zIndex: 10,
          }),
        ];
      }
      this.addMap = new AMap.Map('addMap', {
        layers: layer,
        center: this.$Center,
        expandZoomRange: true, // 扩展地图缩放级别
        zoom: 16,
        zooms: [3, 20],
        resizeEnable: true,
      });
      let autoComplete = new AMap.Autocomplete({ input: 'mapSearch_2' });
      AMap.event.addListener(autoComplete, 'select', function (e) {
        that.addMap.setCenter([e.poi.location.lng, e.poi.location.lat]);
      });
      this.location = '';
      //   if (this.$online) {
      //     this.addMap.setMapStyle('amap://styles/whitesmoke');
      //   }
      // if (Boolean(this.orthographicUpLoadData.e_id)) {
      //   marker = marker = new AMap.Marker({
      //     position: [that.orthographicUpLoadData.lng, that.orthographicUpLoadData.lat],
      //     map: that.addMap,
      //   });
      //   that.getAddsByPosition([
      //     that.orthographicUpLoadData.lng,
      //     that.orthographicUpLoadData.lat,
      //   ]);
      // }
      this.addMap.on('click', function (e) {
        if (that.$online) {
          that.uploadData.lng = e.lnglat.R;
          that.uploadData.lat = e.lnglat.Q;
          that.getAddsByPosition(e.lnglat);
        } else {
          that.uploadData.lng = e.lnglat.O;
          that.uploadData.lat = e.lnglat.P;
        }
        if (marker == '') {
          marker = new AMap.Marker({
            position: e.lnglat,
            map: that.addMap,
          });
        } else {
          marker.setPosition(e.lnglat);
        }
      });
    },
    // 上传格式错误
    handleFormatError(file) {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '请选择jpg或png格式文件',
      });
    },
    // 上传失败
    addMediaError(error) {
      this.$Message.error(error);
    },
    handleMaxSize() {
      this.$Notice.warning({
        title: '超过限定大小限制',
        desc: '上传的图片大小不得大于10M.',
      });
    },
    // 清除上传文件
    clearImage() {
      this.uploadData.image = '';
      this.uploadFormData.delete('image');
      this.fileName = '';
    },
    // 上传-编辑
    // ok() {
    //   this.$refs['formValidate'].validate((valid) => {
    //     if (valid) {
    //       if (!this.fileName) {
    //         this.$Notice.warning({
    //           title: '未选择全景文件',
    //           desc: '请选择jpg或png格式',
    //         });
    //         setTimeout(() => {
    //           this.loading = false;
    //           this.$nextTick(() => {
    //             this.loading = true;
    //           });
    //         }, 1000);
    //         return;
    //       }
    //       if (!this.uploadData.lat || !this.uploadData.lng) {
    //         this.$Notice.warning({
    //           title: '未选择全景图位置',
    //           desc: '请点击地图选择全景图位置',
    //         });
    //         setTimeout(() => {
    //           this.loading = false;
    //           this.$nextTick(() => {
    //             this.loading = true;
    //           });
    //         }, 1000);
    //         return;
    //       }
    //       let eid = sessionStorage.getItem('Eid')
    //         ? sessionStorage.getItem('Eid')
    //         : '';
    //       this.uploadFormData.append('mapName', this.uploadData.mapName);
    //       this.uploadFormData.append('lat', this.uploadData.lat);
    //       this.uploadFormData.append('lng', this.uploadData.lng);
    //       this.uploadFormData.append('pid', 0);
    //       this.uploadFormData.append('eid', eid);

    //       this.$post(PanoramawebAdd(), this.uploadFormData)
    //         .then((res) => {
    //           if (res.code === 1) {
    //             this.uploadModal = false;
    //             this.$Message.success(res.msg_customer);
    //             this.getPanorame();
    //           } else {
    //             this.$Message.error(res.msg_customer);
    //           }
    //           this.uploadModal = false;
    //           this.uploadFormData = new FormData();
    //         })
    //         .catch((err) => {
    //           this.$Message.error(err);
    //           this.uploadFormData = new FormData();
    //         });
    //     } else {
    //       setTimeout(() => {
    //         this.loading = false;
    //         this.$nextTick(() => {
    //           this.loading = true;
    //         });
    //       }, 1000);
    //     }
    //   });
    // },
    // 取消上传
    cancel() {
      this.clearImage();
      // this.$refs['formValidate'].resetFields();
      this.uploadFormData = new FormData();
    },
    delsure() {
      this.$post(PanoramawebDel(), {
        panorama_id: this.rowId,
      })
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            this.$Message.success(res.msg_customer);
            this.getPanorame();
          } else {
            this.$Message.error('系统错误，请联系管理员');
          }
          this.delLoading = false;
          this.delModel = false;
        })
        .catch((err) => {
          console.log(2222);
          this.delLoading = false;
          this.$Message.error(err);
        });
    },
    // 编辑
    edit(el) {
      console.log(el);
      this.$router.push({
        name: 'EdtiPanorama',
        query: { id: el.id, watch: 1 },
      });
      let panoramaInfo = JSON.stringify(el);
      localStorage.setItem('panoramaInfo', panoramaInfo);
    },
  },

  mounted() {
    this.uploadUrl = PanoramawebAdd();
    this.getPanorame();
    this.$_bus.$off('addPanorma').$on('addPanorma', this.add);
  },
};
</script> 

<style lang="scss" scoped>
.map-box {
  float: left;
  width: 2.36rem;
  height: 1.66rem;
  border-radius: 7px;
  margin-right: 0.24rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  position: relative;
  background: $xf_hei1;
  .type-name {
    position: absolute;
    border-radius: 7px 0 0 0;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 0 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #c9caca;
  }
  .map {
    img {
      width: 100%;
      border-radius: 7px 7px 0 0;
      height: 1.2rem;
    }
    width: 100%;
    height: 1.11rem;
    border-radius: 7px 7px 0 0;
  }
  .info {
    padding: 10px 10px 0;
    color: #a0a0a0;
    font-size: 0.12rem;

    .title {
      color: $font_color_1;
      font-size: 0.14rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 2rem;
      height: 0.5rem;
      line-height: 0.5rem;
    }
    // .remark {
    //   margin-top: 0.13rem;
    //   color: #656565;
    //   width: 2rem;
    //   overflow: hidden; /* 溢出隐藏 */
    //   display: -webkit-box; /* 老版弹性盒子，存在浏览器兼容问题，需要添加-webkit，表示内容在水平方向上的排放 */
    //   -webkit-line-clamp: 2; /* 溢出的行数 */
    //   -webkit-box-orient: vertical; /* 规定内容水平排放 */
    //   text-overflow: ellipsis;
    //   white-space: pre;
    // }
    .more {
      position: absolute;
      top: 1.35rem;
      right: 0.05rem;
      > div {
        display: none;
        position: absolute;
        width: 1.06rem;
        height: 1rem;
        // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: $xf_hei4;
        top: 25px;
        right: 4px;
        z-index: 9;
        overflow: hidden;
        p {
          width: 100%;
          color: $font_color_1;
          height: 0.5rem;
          font-size: 0.14rem;
          line-height: 0.5rem;
          text-align: center;
        }
        p:hover {
          background-color: $xf_hui7_hover;
        }
      }
    }
    // .more:hover div {
    //   display: block;
    // }
    .more:hover {
      height: 22px;
      border-radius: 50%;
      background-color: $xf_hui3;
    }
  }
}
.container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;

  .item {
    padding: 10px;
    width: 16.66%;
    height: 200px;
    cursor: pointer;
    .txt {
      padding: 10px 0;
    }
    img {
      width: 100%;
      height: 90%;
    }
  }
  .itemBtn {
    position: relative;
    top: -50px;
    left: 185px;
    button {
      display: none;
      margin-right: 25px;
      opacity: 0;
      transition: all 0.3s linear;
    }
  }
}
.container:hover button {
  opacity: 1;
  display: inline-block;
}
.page {
  padding: 10px 20px;
  float: right;
  clear: both;
}
.ico {
  cursor: pointer;
}

.thumbnail {
  width: 140px;
  height: 80px;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0 20px;
}
.noMedia {
  font-size: 0.16rem;
  text-align: center;
  // margin: 50px 0;
  img {
    margin: 1rem auto 0.2rem;
  }
}
#addMap {
  width: 100%;
  height: 190px;
}
#map {
  width: 648px;
  height: 470px;
  position: relative;
  .mapSearch {
    position: absolute;
    top: 14px;
    left: 11px;
    z-index: 400;
    width: 290px;
    height: 40px;
    background: rgba(23, 23, 23, 0.71);
    border: 1px solid #454545;
    line-height: 40px;
    text-align: left;

    input {
      background: transparent;
      outline: none;
      border: 0;
      color: #fff;
    }
  }
  #addMap {
    width: 100%;
    height: 100%;
  }
  .tiptop {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 30;
    left: 0;
    text-align: center;
    background-color: #f19900;
    color: #000;
    height: 25px;
    line-height: 25px;
  }
}
</style>